@font-face {
  font-family: "HelveticaNeue";
  src: local("HelveticaNeue"),
    url(./fonts/HelveticaNeue.ttf) format("truetype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.name {
  font-family: "HelveticaNeue";
  font-weight: bold;
  font-style: italic;
  font-size: 92px;
  letter-spacing: -0.06em;
  transform: scale(1, 0.69);
  -webkit-transform: scale(1, 0.69); /* Safari and Chrome */
  -moz-transform: scale(1, 0.69); /* Firefox */
  -ms-transform: scale(1, 0.69); /* IE 9 */
  -o-transform: scale(1, 0.69); /* Opera */
  margin: 30px 0;
}

.small {
  font-size: 32px;
  margin: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
